import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import  '../images/vpc-logo.png'
import styled from "styled-components";
import { FaAlignJustify } from 'react-icons/fa'
import { Link } from "gatsby";


const HeaderStyles = styled.header`
  padding-top:2.5rem;
  position:sticky;
  height: auto !important;
  .flexParent {
    display:flex;
    align-items:center;
    justify-content:flex-start;
    column-gap:clamp(2rem, 1.4vw, 4rem);
  }
  .mobileOnly {
    display:none;
  }

  a {
    font-size:clamp(1.4rem, 1.5vw, 2rem);
    text-decoration:none;
    color:#000;
  }
  .siteAnchor {
    display:flex;
    align-items:center;
    font-weight:900;
    max-width:250px;
    gap:1.6rem;
    p {
      max-width:75px;
    } 
  }

  .menuToggle {
    background:none;
    outline:none;
    border:none;
    svg {
      font-size:2.5rem;
    }
  }

  .navMain {
    display:flex;
    align-items:center;
    padding-left:clamp(2rem, 2.4vw, 4rem);
    column-gap:clamp(1.5rem, 3.5vw, 5.4rem);
    text-align:center;
    a {
      --color:#000;
      font-weight:500;
      color:var(--color);
      position:relative;
      width:100%;
      display:inline-block;
      &.active {
        font-weight:700;
        --color:#FC7A57;
      }
      &::after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 0.2rem;
        left: 0;
        position: absolute;
        background: var(--color);
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
    }
    li:hover a,
    li:focus-within a {
      font-weight:600;
    }
    li:hover a::after,
    li:focus-within a::after {
      width:100%;
    }
    
  }
  @media screen and (max-width:650px){
    background:#fcfcfc;
    .flexParent {
      justify-content:space-between;
      flex-wrap:wrap;
    }
    .mobileOnly {
      display:block;
    }
    .navMain {
      flex-direction:column;
      align-items:center;
      width:100vw;
    }
    .navMenu {
      height:0px;
      overflow:hidden;
      transition: 0.8s ease-in-out height;
      &.open {
        height:23rem;
      }
      li {
        padding: 1rem 0;
      }
    }
  }
`
export default function Header(){
  const [navOpen, setNavOpen] = useState(false)
  const navToggle = () => {
    setNavOpen(!navOpen)
  }
  return (
    <HeaderStyles>
      <div className="wrapper">
        <div className="flexParent">
            <Link
            onClick={() => setNavOpen(false)}
            to="/" 
            className="siteAnchor">
              <StaticImage src={'../images/vpc-logo.png'} alt="Vaughan Pediatric Clinic" 
              layout="fixed"
              loading="lazy"
              quality="100"
              placeholder="blurred"
              width={86}/>
              <p>Vaughan Pediatric Clinic</p>
            </Link>
            <button className="mobileOnly menuToggle" onClick={navToggle}>
              <FaAlignJustify />
            </button>
            <nav className={navOpen ? "navMenu open" : "navMenu"}>
              <ul className="navMain">
                <li>
                  <Link to="/staff" activeClassName="active" onClick={() => setNavOpen(false)}>Our Staff</Link>
                </li>
                <li>
                  <Link to="/policies" activeClassName="active" onClick={() => setNavOpen(false)} >Our Policies</Link>
                </li>
                <li>
                  <Link to="/blog" activeClassName="active" onClick={() => setNavOpen(false)}>Clinic News</Link>
                </li>
                <li>
                  <Link to="/circumcisionClinic" activeClassName="active" onClick={() => setNavOpen(false)}>Circumcision Clinic</Link>
                </li>
                <li>
                  <Link to="/contact" onClick={() => setNavOpen(false)} activeClassName="active" >Contact</Link>
                </li>
                <li>
                  <a href="https://kidsnfamilytravelclinic.ca/" target="_blank" rel="noopener noreferrer" onClick={() => setNavOpen(false)} activeClassName="active" >Travel Clinic</a>
                </li>
              </ul>
            </nav>  
        </div>
      </div>
    </HeaderStyles>
  )
}