import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`
  a {
    text-decoration:none;
    color:var(--black);
    &.hoverable {
      text-decoration:underline;
      &:hover,
      &:focus,
      &:active {
        font-weight:600;
      }
    }
    &:not(.siteAnchor){
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap:break-word;
    }
  }

  h1,
  h2 {
    font-weight:700;
  }
  
  h3 {
    font-weight:900;
  }
  h1 {
    font-size:clamp(3.5rem, 2.5vw, 5rem);
    color:var(--green);
    span {
      display:block;
      font-size:clamp(4rem, 2.5vw, 5rem);
      color:var(--black);
    }
  }

  h2 {
    color:var(--black);
    font-size:clamp(2.5rem, 2.4vw, 3.5rem);
    &.red {
      text-align:right;
    }
  }

  h3 {
    font-size:2.1rem;
    margin:4px 0;
  }

  p,
  blockquote {
    font-size:clamp(1.4rem, 2.4vw, 1.8rem);
    line-height:2.1rem;
    &.name {
      font-weight:700;
      font-size:clamp(1.8rem, 2.4vw, 2.4rem);
    }
  }
  .strong {
    font-weight:700;
  }

  button.cta {
    color:var(--black);
  }
  .cta {
    display:block;
    font-size:clamp(1.8rem, 2.4vw, 2rem);
    font-weight:600;
    max-width:240px;
    text-align:center;
    margin: 15px auto;
    padding:1.7rem 0.5rem;
    --background: var(--white);
    background-color:var(--background);
    border:solid var(--black) 0.3rem;
    border-radius:2rem;
    padding:15px;
    cursor: pointer;
    &.green {
      --background: var(--green);
      color:var(--white);
    }
    &.purple {
      --background: var(--purple);
      color:var(--white);
    }
    &.blue {
      --background: var(--blue);
      color:var(--white);
    }
    &.orange {
      --background: var(--orange);
      color:var(--white);
    }
    &:hover,
    &:focus {
      background:var(--white);
      color:var(--black);
      text-decoration: underline;
    }
  }
  .italic {
    font-style:italic;
  }

  .postContainer,
  .blogItem {
    a:not(.cta){
    color:var(--red);
    &:hover,
    &:focus {
      font-weight:500;
    }
    }
  }


`

export default Typography