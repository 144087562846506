import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import { TbCircleX } from 'react-icons/tb'

gsap.registerPlugin(ScrollTrigger)
ReactModal.setAppElement('#___gatsby')
export default function FluShotModal() {
  const [modalOpen, setModalOpen] = useState(false)
    useEffect(() => {
      if(!typeof window !== 'undefined' && !window.sessionStorage.getItem('modalViewed')){
        setTimeout(() => {
          setModalOpen(true)
        }, 500)
      }
    }, [])
    
    function handleModalClose() {
      setModalOpen(false)
      sessionStorage.setItem('modalViewed', true)
    }
  
  return (
    <>
    <ReactModal
    isOpen={modalOpen}
    role={"dialog"}
    portalClassName="popUpModal"
    onRequestClose={handleModalClose}
    preventScroll={true}
    shouldFocusAfterRender={true}
    aria={{
      labelledby: 'modalTitle',
      describedby: 'modalText'
    }}
    style = {
     { overlay: {
        backfaceVisibility: 'hidden',
        position:'fixed',
        zIndex:'800',
        overflow:"hidden !important",
        background:`rgb(0, 0, 0, 0.64)`
      },
      content: {
        borderRadius:'20px',
        inset: '40px',
        maxWidth:'450px',
        border:'solid 4px var(--black)',
        overflow:'scroll',
        margin:'auto',
        height:'fit-content',
        maxHeight:'80vh',
        padding:'45px 25px'
      }
    }
    }
    contentLabel={`Flu Shot Update`}>
      <button className="modalClose" aria-label="close" title="Close" onClick={handleModalClose}><TbCircleX /></button>
      <h2>Flu Shot & RSV Vaccine Clinics Now Open</h2>
      <p>Exciting news!! We have the RSV shot available in the office for all infants born in 2024. You can book online for this week by clicking the link below. Once we receive more supply we will open up additional spots at a later date.</p>
        <a href="https://ocean.cognisantmd.com/online-booking/9b8db9f1-657a-4e46-9216-b9e3a8d20fe1" className="cta purple" target="_blank" rel="noopener noreferrer">Book Your RSV Vaccine</a>
      <p>In addition, we will open up some spots for the flu vaccine during this week as well. To book please click on the link below.</p>
      <a href="https://ocean.cognisantmd.com/online-booking/12f5c862-4930-4d04-8671-37939e058501" className="cta green" target="_blank" rel="noopener noreferrer">Book Your Flu Shot</a>
    </ReactModal>
    </>
  )
}